.previewRaduis img{
    width: 40px;
    height: 40px;
    border-radius: 100%;
}
.previewRaduis .removeImg{
    top: 9px;
    position: absolute;
    left: auto !important;
    color: red;
    font-size: 12px;
    margin-left: -35px !important;
    cursor: pointer;
}
.clientProduct{
    height: 35px;
    border-radius: 100%;
    width: 35px;
    border: 1px solid;
}
.clientForm .form-control:disabled, .clientForm .form-control[readonly] {
    background-color: #e9ecef !important;
    opacity: 1;
}
.clientProductCount{
    display: block;
    font-weight: 500;
    position: relative;
    bottom: 40px;
    background: #dedede;
    width: 16px;
    height: 16px;
    margin-left: 0px;
    border-radius: 100%;
    font-size: 12px;
    padding: 0px 4px;
}
.showImge{
    width: 20px !important;
    height: 20px !important;
}