button{
    text-transform: inherit !important
}
.calcImg{
    width: 70px;
    margin-left: 30%;
}
.moreBtn{
    width:60px;
}
@media(max-width: 1212px){
    .calcImg{
        margin-left: 20%;
    }
}
@media(max-width: 1120px){
    .calcImg{
        margin-left: 10%;
    }
    .moreBtn{
        width:40px;
    }
}
@media(max-width: 992px){
.calcImg {
    margin-left: 76%;
}
}
@media(max-width: 760px){
    .calcImg {
        margin-left: 65%;
    }
}
@media(max-width: 630px){
    .calcImg {
        margin-left: 30%;
    }
}