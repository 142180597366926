.editPremuims .form-control:disabled, .form-control[readonly] 
,.editPremuims .ant-select-disabled.ant-select-multiple .ant-select-selector,
.editPremuims .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: #fff !important;
}
.theTotal .form-control:disabled, .form-control[readonly]{
    background: #E9ECEF !important;
}
.editPremuims .package{
    background: #E9ECEF !important;
    border-radius: 8px !important;
    
    box-shadow:8px 9px 8px #e9ecef !important
}
.editPremuims .packageInput {
    border-radius: 6px !important;
}
.editPremuims .ant-select-selector {
    min-height: 38px !important;
    margin-bottom: 4px !important;
}
.editPremuims{
    background: #fff;
    padding-bottom: 2rem;
    min-height:600px
}
.editPremuims .formLabel{
    font-size: 12px !important;
    margin-top: 5px !important;
}

.editPremuims .ant-select-show-search.ant-select-multiple .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    border:.5px solid #aaa !important;
}
.editPremuims .addProduct {
    
    color: #31313F;
    font-size: 17px;
    font-weight: 600;
    margin: 5px 0px;
    position: absolute;
    top: 30px;
    cursor: pointer;
    right: 60px;
    
}
.editPremuims .minusInput{
    color: red;
    text-align: center;
    width: 30px;
    height: 30px;
    padding: 12px 0px;
    border-radius: 20px;
    cursor: pointer;
    position: absolute;
    margin:0% 30%;
    top: 35px;
    font-size: 12px;
}
.editPremuims .ant-tree-select-dropdown {
    left: 1040px !important;
}
.tabelDevEdit{
    padding: 10px;
}
.tabelDevEdit table.table th {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    text-align: center;
    font-weight: 400;
    border: none;

}
.tabelDevEdit table.table tr {
    border:1px solid rgb(142, 139, 139)

}
.tabelDevEdit table.table {
    background:#fff !important
}
.tabelDevEdit .table .thead-dark th {
    color: #000 !important;
    background-color: rgb(121, 116, 116) !important;
    border-color: rgb(121, 116, 116) !important;
    border: 1px solid rgb(121, 116, 116);
    font-weight: 600;
    font-size: 14px;
}
.tabelDevEdit table.table th, table.table td {
    padding-top:8px !important;
    padding-bottom: 8px !important;
}