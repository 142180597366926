.Problem{
    background: #ffffff;
    min-height: 500px;
    box-shadow: 1px 2px 6px #dedede;
}
.problemInfo{
    background: #eaedf0b9;
    height: 250px;
    margin: 15px;
    padding: 15px 25px;
    margin-top: 85px;
    box-shadow: 1px 2px 6px #dedede;
    margin-right: 0;

}
.problemUserInfo{
    background: #eaedf0b9;
    height: 205px;
    margin: 15px;
    padding: 10px;
    padding: 15px 25px;
    box-shadow: 1px 2px 6px #dedede;
    margin-right: 0;
}
.problemsRight{
    border-left: 1px solid #dedede;
    height: 100%;
}
.problemsRight h3{
    text-align: center;
    padding: 25px;
    font-weight: 400;
    color: #43425D;
    margin: 0;
    font-size: 22px;
}
.proplemsItems{
    overflow-y: auto;
    height: 475px;
}
.proplemsItems::-webkit-scrollbar {
    width: 14px;
}
  
.proplemsItems::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}
.proplemsItems::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
}
.proplemItems{
    height: 80px;
    width: 100%;
    margin-bottom: 1px;
    padding: 10px;
    background: #eaedf0b9;
    cursor: pointer;
}
.problemRight{
    width: 70%;
    float: right;
    text-align: right;
    padding: 0px 10px;
}
.problemLeft{
    width: 30%;
    float: left;
    padding: 8px 1px;
}
@media (max-width: 991px){
    .mdRevers{
        flex-direction: column-reverse !important;
    }
    .problemInfo{
        margin-right: 15px;
    }
    .problemUserInfo{
        margin-right: 15px;
    }
}