.chat{
    background: #fff;
    margin-top: 1rem;
}
.chatPeaple{
    height: 602px;
    overflow-y: scroll;
    border: 1px solid #e4ecea;
    border-top: 0;
}
.chatPeaple h3{
    padding: 13px;
    padding-top: 25px;
    text-align: center;
}
.paddingLeft ,.paddingRight{
    padding: 0;
}
.inbox{
    min-height: 600px;
    /*border: 1px solid #e4ecea;*/
}
.topInbox{
    border-bottom: 1px solid #e4ecea;
    height: 80px;
    padding: 1rem;
}
.topInbox .MuiAvatar-root{
    display: inline-flex;
    width: 50px;
    height: 50px;
}
.topInbox p{
    display: inline-block;
    font-weight: 400;
    margin-top: 0rem;
    position: absolute
}
.topInbox .btn-default{
    color: #5a8e7d !important;
    background: #d1dfdb !important;
    border-radius: 5px;
    box-shadow: none;
    padding: 10px 15px 10px 15px;
    float: right;
    font-weight: 500;
}
.bodyInbox{
    /*border: 1px solid #e4ecea;*/
    height: 400px;
    padding: 2rem;
    overflow-y: scroll;
    overflow-x: hidden;
    
}
.bodyInbox::-webkit-scrollbar {
    width: 8px;
    background-color: rgba(207, 207, 207, 0);
}
  
.bodyInbox::-webkit-scrollbar-thumb {
background: #90A4AE;
border-radius: 5px;
}

/*chatPeaple*/
.chatPeaple::-webkit-scrollbar {
    width: 8px;
    background-color: rgba(207, 207, 207, 0);
}
  
.chatPeaple::-webkit-scrollbar-thumb {
background: #90A4AE;
border-radius: 5px;
}
.sender .MuiAvatar-root {
    width: 40px;
    height: 40px;
    top: -1.8rem;
    /*display: inline-block;*/
    position: initial;
    float: left;
}
.message{
    display: inline-block;
    min-width: 80px;
    max-width: 500px;
    font-size: 12px;
    background: #edf2f4;
    border-radius: 18px;
    padding: 10px;
    margin-left: 1rem;
    min-height: 50px;
}
.message p{
    margin-bottom: 0px;
    font-weight: 400;
    line-break: anywhere
}
.msgTime{
    font-weight: 400;
    color: #999;
    font-size: 13px;
    margin-left: 4.4rem;
    padding-top: .5rem;
}
.me .MuiAvatar-root{
    float: right;
    width: 40px;
    height: 40px;
    position: initial;
}
.me .message{
    float: right;
    margin-right: 1rem;
    background: #4285F4;
    color: #fff;
    border-bottom-right-radius: 0px;
}
.sender .message{
    background: #F0F2F5;
    color: #999;
    border-bottom-left-radius: 0px;
}
.me .msgTime{
    padding-right: 4.4rem;
    width: 100%;
    text-align: right;
    color: #fff;
}
@media (max-width: 1250px){
    .inboxFooter .fa-paperclip{
        left: 40px;
    }
}
@media (max-width: 1200px){
    
.message {
    display: inline-block;
    min-width: 341px;
    max-width: 400px;
}
}
@media (max-width: 991px){
    .changePic {
        width: 95px !important;
        margin-left: 18.5rem !important;
    }
}
@media (max-width: 767px){
    .message{
       
        max-width: 50px;
    }
    .tab {
        display: inline-block;
        width: 60%;
        margin-left: 10px;
    }
    .changePic {
        width: 95px !important;
        margin-left: 12.5rem !important;
    }
    
}
@media (max-width: 543px){
    .message{
       
        min-width: 300px;
    }
    .sender .MuiAvatar-root {
        width: 50px;
        height: 50px;
        top: -4.8rem;
        display: inline-block;
    }
    .inboxFooter .fa-paperclip{
        left: 40px !important;
    }
    .changePic {
        width: 95px !important;
        margin-left: 11.5rem !important;
    }
}
.me , .sender{
    width: 100%;
    min-height: 140px;
    display: inline-block
}
.inboxFooter{
    min-height: 120px;
    border-top: 1px solid #e4ecea;
    padding: 2rem;
}
.inboxFooter .form-control{
    width: 100% !important;
    background: #f5f6fa;
    border-radius: 4px;
    outline: none;
    resize: none;
    border: none
}
.inboxFooter .form-control::placeholder { 
    color:#80bdff;
    font-size: 14px;
    padding-top: 1rem;
  }
  .inbox textarea::placeholder{
    color: #80bdff !important;
  }
.chat .form-control::placeholder { 
    font-size: 14px;
    font-weight: 400;
    color: #80bdff !important;
  }

.chatSearch .form-group {
    margin-bottom: 0rem;
}
.chat .form-control:focus{
    outline: none !important;
    border: none !important;
}
.has-search .form-control {
    padding-left: 2.375rem;
    width: 100% !important;
    border: 1px solid #e4ecea;
    height: 80px;
    padding: 2rem;
    border-radius: 0px;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 5.2rem;
    text-align: center;
    pointer-events: none;
    color: #cccdd3;
}
.chatTabs .MuiAvatar-root{
    width: 45px;
    height: 45px;
    top: 6px;
    float: right;
}

.chatTabs{
    border-bottom: 1px solid #e4ecea;
    height: 76px;
    padding: .4rem 1rem;
    border-right: 0px;
}
.tab{
    display: inline-block;
    width: 80%;
    margin-left: 0px;
}
.tab .name{
    font-size: 12px;
    font-weight: 500;
    float: right;
    width: 100px;
    text-align: end;
}
.tab .time{
    font-size: 11px;
    /*display: inline-block;
    float: right;*/
    color: #999;
    font-weight: 400;
    margin-top: 4px;
}
.tab .shortMsg{
    font-size: 14px;
    font-weight: 400;
    color: #868686;
    float: right;
    margin-top: 0px;
    text-align: right;
    width: 72%;
}

@media (min-width: 1200px){
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1200px;
}
}
@media (max-width: 480px){
    .sender .MuiAvatar-root {
        width: 40px;
        height: 40px;
        top: -7.8rem;
    }
    .me .MuiAvatar-root {
        float: right;
        width: 40px;
        height: 40px;
    }
    .message {
        min-width: 260px;
    }
    .topInbox .btn-default {
        
        padding: 5px 10px 5px 10px;
        font-weight: 400;
    }
    .topInbox {
        padding: 1rem .5rem;
    }
    .topInbox .dropdown .btn {
        width: 20px !important;
        padding: 2px !important;
    }
}
@media (max-width: 440px){
    .sender .MuiAvatar-root {
        width: 40px;
        height: 40px;
        top: -7.8rem;
    }
    .me .MuiAvatar-root {
        float: right;
        width: 40px;
        height: 40px;
    }
    .message {
        min-width: 200px;
    }
    .topInbox .btn-default {
        
        padding: 5px 10px 5px 10px;
        font-weight: 400;
    }
    .topInbox p {
        display: inline-block;
        font-weight: 400;
        margin-top: .8rem;
        margin-left: 0rem !important;
    }
}
@media (max-width: 360px){
    .message {
        min-width: 140px;
    }
}
.topInbox .dropdown{
    /*display: inline-block;*/
    float: right;
    
}
.topInbox .dropdown-menu{
    transform: translate3d(-120px, 50px, 0px) !important;
}
.topInbox .dropdown .btn{
    width: 25px;
    /*display: inline-block;*/
    float: right;
    padding: 6px;
    background: transparent !important;
    box-shadow: none;
    color: #000;
}
.topInbox .dropdown-toggle::after {
    display: none
}
.topInbox .dropdown .btn:focus{
    background: transparent !important;
    box-shadow: none !important;
}
.inboxFooter .fa-paperclip{
    float: left;
    padding-left: 4px;
    position: absolute;
    left: 40px;
    z-index: 200;
    cursor: pointer;
    color: #d8d8d8;
    margin-top: -1.6rem;
}
.inboxFooter .fa-image{
    float: left;
    padding-left: 4px;
    position: absolute;
    left: 42px;
    z-index: 200;
    cursor: pointer !important;
    color: #d8d8d8;
    margin-top: -1.6rem;
    transition:all ease .5s;
}
.inboxFooter .uploadFile{
    width: 34px;
    margin-left: 10px;
    margin-top: -2rem;
    position: absolute;
    opacity: 0;
    z-index: 2000000;
    cursor: pointer !important;
}
.uploadFile:hover + .fa-image{
    color: blue !important ;
    cursor: pointer;
}
.inputsCont{
    background: #f5f6fa;
    min-height: 86px;
}
.offline{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 4px;
    background-color: #aaa;
    /*
    float: left;
    position: relative;
    margin-top: 25px;
    */
}
.online{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 4px;
    background-color: #38d838;
    /*
    float: left;
    position: relative;
    margin-top: 25px;
    */
}
.chatSearch .ant-input-affix-wrapper-lg {
    padding: 7.5px 11px;
    font-size: 16px;
    height: 80px;
}
.chatSearch .ant-input-prefix {
    margin-right: 10px;
    margin-left: 4px;
}

.chat .ant-input-affix-wrapper:hover,.ant-input-affix-wrapper:focus{
    border: 1px solid #e4ecea;
    border-right-width:0px !important
}
.changePic{
    width: 95px;
    margin-left: 5.4rem;
    margin-top: -6.6rem;
    position: absolute;
    opacity: 0;
    z-index: 2000000;
    cursor: pointer;
    height: 96px;
    border-radius: 50%;
}