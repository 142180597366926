.theClient .form-control:disabled, .form-control[readonly] {
    background: #fff !important;
}
.theTotal .form-control:disabled, .form-control[readonly] {
    background: #E9ECEF !important;
}
.multiSale .package{
    background: #E9ECEF !important;
    border-radius: 8px !important;
    
    box-shadow:8px 9px 8px #e9ecef !important
}
.multiSale .packageInput {
    border-radius: 6px !important;
}
.multiSale .ant-select-selector {
    height: 70% !important;
    min-height: 38px !important;
    margin-bottom: 4px !important;
}
.multiSale{
    background: #fff;
    padding-bottom: 2rem;
    min-height:600px
}
.multiSale .formLabel{
    font-size: 12px !important;
    margin-top: 5px !important;
}

.multiSale .ant-select-show-search.ant-select-multiple .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    border:.5px solid #aaa !important;
}
.multiSale .addProduct {
    
    color: #31313F;
    font-size: 17px;
    font-weight: 600;
    margin: 5px 0px;
    position: absolute;
    top: 30px;
    cursor: pointer;
    right: 60px;
    
}
.multiSale .minusInput{
    color: red;
    text-align: center;
    width: 30px;
    height: 30px;
    padding: 12px 0px;
    border-radius: 20px;
    cursor: pointer;
    position: absolute;
    margin:0% 30%;
    top: 35px;
    font-size: 12px;
}
.multiSale .ant-tree-select-dropdown {
    left: 1040px !important;
}
