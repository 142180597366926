.tabelNewReciept table.table td {
  padding-top: 0px !important; /**/
  padding-bottom: 0px !important; /**/
  text-align: center;
  font-weight: 600;
  border: 2px solid #000 !important;
  border-right: 4px solid #000 !important;
}
.tabelNewReciept table.table th {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  text-align: center;
  font-weight: 600;
  border: 2px solid #000 !important;
}
.tabelNewReciept table.table {
  background: #fff !important;
  border: none !important;
  margin-bottom: 0px;
}
.tabelNewReciept .table .thead-light th {
  color: #000 !important;
  background-color: #ffff !important;
  border-color: #000 !important;
  border: none !important;
  font-weight: 600;
  font-size: 14px;
}

.tabelNewReciept2 table.table td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  text-align: center;
  font-weight: 600;
  border: none !important;
}
.tabelNewReciept2 table.table th {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  text-align: center;
  font-weight: 600;
  border: none !important;
}
.tabelNewReciept2 table.table {
  background: #fff !important;
  border: none !important;
  margin-bottom: 0px;
}
.tabelNewReciept2 .table .thead-light th {
  color: #000 !important;
  background-color: #ffff !important;
  border: none !important;
  font-weight: 600;
  font-size: 14px;
}

.tabelNewReciept2 tr {
  width: 100% !important;
  display: table !important;
}

.topAdvURL {
  /* background-color: red; */
  font-size: 16px;
  height: 30px;
  overflow: "hidden";
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 6px;
}
.topCompanyName {
  /* background-color: blue; */
  font-size: 30px;
  font-weight: 700;
  height: 40px;
  overflow: "hidden";
}

.topCompanyDesc {
  /* background-color: green; */
  font-size: 16px;
  height: 30px;
  overflow: "hidden";
  padding-top: 5px;
  padding-bottom: 5px;
}

.tableItem {
  /* background-color: yellow; */
  font-size: 19px;
  height: 20px;
  overflow: "hidden";
  /* padding-top: 2px; */
  /* margin-top: -15px; */
  padding-bottom: 2px;
}

.bottomCompanyInformation {
  /* background-color: aqua; */
  margin-top: 2px;
  font-size: 16px;
  font-weight: 600;
  border: 3px solid #000 !important;
  height: 40px;
}


.salesMenDiv{
   font-size: 18px;
  height: 36px;
  text-align:center;
  margin-top:14px;
  padding-bottom: 2px;
}

.salesMenSpan{
  overflow: hidden;
  height: 36px;
  border: 2px solid #000 !important;
  text-align:center;
  padding-top:2px;
  padding-bottom: 2px;
}