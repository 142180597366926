
.tabelOrders table.table td {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    text-align: center;
    font-weight: 600;
    border: 2px solid #000;
}
.tabelOrders table.table th {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    text-align: center;
    font-weight: 600;
    border: 2px solid #000;
}
.tabelOrders table.table {
    background:#fff !important
}
.tabelOrders .table .thead-light th {
    color: #000 !important;
    background-color: #dbdada !important;
    border-color: #000 !important;
    border: 2px solid #000;
    font-weight: 600;
    font-size: 14px;
}