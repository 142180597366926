.CompanyForm .ant-select-selector {
    height: 38px !important;
    margin-bottom: 4px !important;
}
.CompanyForm{
    background: #fff;
    padding-bottom: 2rem;
}
.CompanyForm .formLabel{
    font-size: 12px !important;
    margin-top: 5px !important;
}
.checking{
    height: 35px;
    background: #dedede;
    width: 100%;
    border-radius: 25px;
}
.checkingRight{
    width: 50%;
    height: 35px;
    float: right;
    border-top-right-radius: 21px;
    border-bottom-right-radius: 20px;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    transition: all .5s ease-in-out
}
.checkingLeft{
    height: 35px;
    float: left;
    border-top-left-radius: 21px;
    border-bottom-left-radius: 20px;
    width: 50%;
    cursor: pointer;
    text-align: center;
    transition: all .5s ease-in-out
}


