.theCard{
    padding: 10px 15px;
    background-color: #fff;
}
.theCard .topCard{
    color: #A4A3B0;
    text-align: right;
    font-weight: 400;
    font-size: 18px;
    font-family: system-ui;
    margin-bottom: 10px;
}
.theCard .count{
    font-size: 22px;
    text-align: right;
    font-weight: 600;
    color: #31313F;
    margin-bottom: 10px;
}
.theCard .arang{
    color: red;
    text-align: right;
    font-size: 15px;
    margin-bottom: 0px;
    font-weight: 500;
}
.homeChart{ 
    background-color:#ffffff;
    background-color: #ffffff;
    padding: 2rem 1rem;
    width: 100%;
}
.centerCount{
    font-size: 30px;
    font-weight: 700; 
    font-family: system-ui;
    color: #43425D;
}
.centerName{
    text-align: right;
    padding-top: 18px;
    font-weight: 600;
    font-size: 18px;
    color: #43425D;
}
.spanCicle{
    background: #fff;
    width: 18px;
    height: 18px;
    display: inline-block;
    border: 3px solid red;
    border-radius: 100%;
    margin-top: 11px;
    position: absolute;

}
.spanNum{
    font-size: 14px;
    padding: 10px;
    display: inline-block;
    font-weight: 500;
    margin-left: 20px;

}
.rightSpan{
    float: right;
    font-weight: 600;
    margin-top: 8px;
    color: #43425D;
}
tr{
    text-align: center;
}
table.table th,
table.table td {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    text-align: center;
}
.table .thead-light th {
    color: #A4A3B0 !important;
    background-color: #F0F0F7 !important;
    border-color: #F0F0F7 !important;
    font-weight: 600;
    font-size: 12px;

}