.orderTabs{
    background: #ffffff;
    height: 600px;
    box-shadow: 1px 1px 4px #dedede;
}
.orders{
    height: 500px;
    overflow-y: auto
}
.orders::-webkit-scrollbar {
    width: 14px;
}
  
.order::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}
.orders::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
}
.ant-tabs-nav {
    width: 100% !important;
    background: #F0F2F5;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    width: 33.2% ;
    height: 85px ;
    text-align: center ;
    margin-right: 1px !important;
    line-height: 60px !important;
    font-size: 18px ;
    color: #43425D !important;
    font-weight: 500 ;
    background: #ffffff;
    font-family: system-ui;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background: #89bff16b;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active::before {
    width: 10px;
    height: 10px;
    background: transparent;
    border-radius: 50%;
    float: right;
    position: initial;
}
.order{
    width: 100%;
    background: #F0F2F5;
    min-height: 90px;
    border: 1px solid #dedede;
    border-top: none;
    cursor: pointer;
}
.orderInfoRight{
    float: right;
    width: 67%;
    text-align: end;
    padding: 6px 30px;
    font-family: system-ui;
    height: 100%;
}
.orderInfoLeft{
    float: left;
    width: 33%;
    height: 100%;
    font-weight: 400;
    color: rgb(144, 143, 154);
    font-size: 16px;
    padding: 25px 15px;
}
.ant-tabs-bar {
    margin: 0  !important;
}
.orderPackage{
    background: rgba(195, 215, 242, 0.56);
    padding: 10px 0px;
    width: 80%;
    margin: 1rem 0rem;
    box-shadow: 3px 5px 7px rgba(195, 215, 242, 0.56);
}
.orderPackageInput{
    border: none;
    color: #478DFF;
    font-size: 15px;
    text-align: center;
    margin-top: -4px;
    background: transparent;
}
.orderPackageLabel {
    font-size: 12px;
    font-weight: 600;
    padding: 6px;
}
.orderType{
    width: 20%;
    float: right;
}
.orderType p{
    text-align: right;
    color: #478DFF;
    font-size: 18px;
    font-family: system-ui;
    font-weight: 400;
    margin-top: 1rem;
}
.notifOrder{
    position: absolute;
    top: 12px;
    right: 12px;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    transition: all 0.3s;
    content: '';
    background: tan;
}
#tab-3::before {
    width: 10px;
    height: 10px;
    background: tan;
    border-radius: 50%;
    float: right;
    position: initial;
}